import React, { Dispatch, FC } from "react";
import { Button, Grid, Text } from "@cet/components";
import { MisTrackingEventsService, t } from "@cet/services";
import { ButtonVariant } from "@cet/constants";
import { ErrorType } from "@cet/interfaces";
import { ActionType, TabAction } from "../store";
import { StatementsService } from "../services";
import { StatementsType } from "../types";
import { useStatements } from "../hooks";

type CurrentSectionProps = {
  dispatcher?: Dispatch<TabAction>;
};

export const LeveragedStatementsCurrentSection: FC<CurrentSectionProps> = ({
  dispatcher,
}) => {
  const { account } = useStatements();
  const misTrackingEventsService =
    MisTrackingEventsService.getInstance<MisTrackingEventsService>();
  const downloadCurrentStatements = () => {
    const trackId = `downloadCurrent${StatementsType.LEVERAGED}Statement`;

    misTrackingEventsService.trackClick(trackId);
    try {
      return StatementsService.downloadStatement(
        StatementsType.LEVERAGED,
        account,
      );
    } catch (error) {
      dispatcher({
        type: ActionType.LEVERAGED,
        payload: {
          tabState: ErrorType.ServerError,
        },
      });
    }
    return null;
  };

  return (
    <Grid.Col mb={[3, 4]}>
      <Grid.Row mb={3}>
        <Text data-testid="Statements.Title.currentStatement" variant="H5">
          {t("Statements.Title.current")}
        </Text>
      </Grid.Row>
      <Grid.Row mb={4}>
        <Text
          variant="BodyMedium"
          data-testid="Statements.Description.currentStatement"
        >
          {t("Statements.Description.currentStatement")}
        </Text>
      </Grid.Row>
      <Grid.Row>
        <Button
          variant={ButtonVariant.Primary}
          onClick={downloadCurrentStatements}
          data-testid="openCurrentStatement"
        >
          {t("Statements.Button.download")}
        </Button>
      </Grid.Row>
    </Grid.Col>
  );
};
