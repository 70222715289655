import React, { useContext } from "react";
import { Account } from "@cet/services";

interface IStatementsContext {
  account: Account;
  locale: string;
}

export const StatementsContext = React.createContext<IStatementsContext>(null);
export const useStatements = () => useContext(StatementsContext);
