import { IFilterData } from "@cet/interfaces";
import { Account, IgHttp, UrlBuilder, UrlUtils } from "@cet/services";
import { StatementsType } from "../types";
import { IReportsDto, IStatementDto } from "./types";
import { ReportType, SelectedPeriod } from "../store";

const getStatements = (
  account: Account,
  type: StatementsType,
  fromDate?: string,
  toDate?: string,
  selectedReport?: SelectedPeriod | ReportType,
): Promise<IStatementDto[]> => {
  const igHttp: IgHttp = IgHttp.getInstance();
  const urlUtils: UrlUtils = UrlUtils.getInstance();
  const { client, clientType, id, selectedProxyAccountId } = account;
  const ifuParams = { "statementTypes": "ANNUAL,TAX_CERTIFICATE" };

  switch (type) {
    case StatementsType.PHYSICAL:
    case StatementsType.LEVERAGED: {
      const url = account.selectedProxyAccountId
        ? `platform-reporting-gateway/statements/broker/${client.id}/account/${id}/proxyAccount/${selectedProxyAccountId}/list`
        : `platform-reporting-gateway/statements/${id}/list`;

      const urlBuilder: UrlBuilder = UrlBuilder.getInstance();
      const fullUrl = urlBuilder.buildDealingRestUrl(clientType, url, {
        fromDate,
        toDate,
      });

      return igHttp
        .getWithCstAndXst<IReportsDto>(clientType, fullUrl, id)
        .then((data: IReportsDto) => data.activityReports);
    }

    case StatementsType.INDUCEMENT:
    case StatementsType.CC: {
      const url = urlUtils.getCCStatementsListGatewayUrl(account);

      return igHttp.getWithCstAndXst<IStatementDto[]>(clientType, url, id);
    }

    case StatementsType.TASTYDAILY: {
      const url = `platform-reporting-gateway/tasty/dailystatement/${id}/list`;

      const urlBuilder: UrlBuilder = UrlBuilder.getInstance();
      const fullUrl = urlBuilder.buildDealingRestUrl(clientType, url);

      return igHttp
        .getWithCstAndXst<IReportsDto>(clientType, fullUrl, id)
        .then((data: IReportsDto) => data.activityReports);
    }

    case StatementsType.TASTYMONTHLY: {
      const url = `platform-reporting-gateway/tasty/monthlystatement/${id}/list`;

      const urlBuilder: UrlBuilder = UrlBuilder.getInstance();
      const fullUrl = urlBuilder.buildDealingRestUrl(clientType, url);

      return igHttp
        .getWithCstAndXst<IReportsDto>(clientType, fullUrl, id)
        .then((data: IReportsDto) => data.activityReports);
    }

    case StatementsType.TASTYYEARLY: {
      if (selectedReport === ReportType.costandcharges) {
        const url = `platform-reporting-gateway/tasty/costandcharges/${id}/list`;
        const urlBuilder: UrlBuilder = UrlBuilder.getInstance();
        const fullUrl = urlBuilder.buildDealingRestUrl(clientType, url);
        return igHttp
          .getWithCstAndXst<IReportsDto>(clientType, fullUrl, id)
          .then((data: IReportsDto) => data.activityReports);
      }
      if (selectedReport === ReportType.ustaxstatement) {
        const url = `platform-reporting-gateway/tasty/annualstatement/${id}/list?region=US`;
        const urlBuilder: UrlBuilder = UrlBuilder.getInstance();
        const fullUrl = urlBuilder.buildDealingRestUrl(clientType, url);

        return igHttp
          .getWithCstAndXst<IReportsDto>(clientType, fullUrl, id)
          .then((data: IReportsDto) => data.activityReports);
      }
      const url = `platform-reporting-gateway/tasty/annualstatement/${id}/list?region=UK`;
      const urlBuilder: UrlBuilder = UrlBuilder.getInstance();
      const fullUrl = urlBuilder.buildDealingRestUrl(clientType, url);
      return igHttp
        .getWithCstAndXst<IReportsDto>(clientType, fullUrl, id)
        .then((data: IReportsDto) => data.activityReports);
    }

    case StatementsType.IFU: {
      const url = `platform-reporting-gateway/statements/${id}/search`;

      const urlBuilder: UrlBuilder = UrlBuilder.getInstance();
      const fullUrl = urlBuilder.buildDealingRestUrl(clientType, url, { ifuParams });

      return igHttp
        .getWithCstAndXst<IReportsDto>(clientType, fullUrl, id)
        .then((data: IReportsDto) => data.activityReports);
    }

    default:
      throw new Error("Incorrect Statement type");
  }
};

const getDownloadUrl = async (
  type: StatementsType,
  urlUtils: UrlUtils,
  account: Account,
  statement: IStatementDto,
  urlBuilder: UrlBuilder,
): Promise<string> => {
  if ([StatementsType.INDUCEMENT, StatementsType.CC].includes(type)) {
    return urlUtils.getCCStatementGatewayUrl(
      account,
      statement.reportReference,
    );
  }
  if ([StatementsType.TASTYDAILY, StatementsType.TASTYMONTHLY, StatementsType.TASTYYEARLY].includes(type)) {
    const url = `platform-reporting-gateway/tasty/accounts/${account.id}/documents/${statement.reportReference}`;
    return urlBuilder.buildDealingRestUrl(account.clientType, url);
  }

  const path = statement
    ? `statement/${statement.reportTypeID}/download`
    : "download";
  const url = account.selectedProxyAccountId
    ? `platform-reporting-gateway/statements/broker/${account.client.id}/account/${account.id}/proxyAccount/${account.selectedProxyAccountId}/${path}`
    : `platform-reporting-gateway/statements/${account.id}/${path}`;

  return urlBuilder.buildDealingRestUrl(account.clientType, url);
};

const downloadStatement = async (
  type: StatementsType,
  account: Account,
  statement?: IStatementDto,
): Promise<void> => {
  const igHttp: IgHttp = IgHttp.getInstance();
  const urlBuilder: UrlBuilder = UrlBuilder.getInstance();
  const urlUtils: UrlUtils = UrlUtils.getInstance();
  const accountId = account.selectedProxyAccountId || account.id;
  const filename = statement
    ? `${statement.reportReference}-${statement.periodEnd}.pdf`
    : `${accountId}-${new Date().toISOString()}-statement.pdf`;
  const downloadUrl = await getDownloadUrl(
    type,
    urlUtils,
    account,
    statement,
    urlBuilder,
  );

  return igHttp
    .getWithCstAndXst<IFilterData>(account.clientType, downloadUrl, account.id)
    .then((data) => {
      urlUtils.getFile(data, filename);
    })
    .catch((err) => err);
};

type params = {
  from: string;
  to: string;
  pageSize: number;
  pageNumber: number;
  type?: string;
  instrumentId?: string;
};

type Pagination = {
  pageSize: number;
  pageNumber: number;
  totalPages: number;
  totalElements: number;
};

export interface CostAndChargesHistoryDto {
  type: string;
  direction: string;
  instrumentName: string;
  createdTimestamp: string;
  indicativeQuoteReference: string;
}

export interface CostAndChargesHistoryResponse {
  pagination: Pagination;
  costsAndChargesHistory: CostAndChargesHistoryDto[];
}

const getExAnteStatements = (
  account: Account,
  params: params,
): Promise<CostAndChargesHistoryResponse> => {
  const { clientType } = account;

  const fullUrl = UrlUtils.getInstance<UrlUtils>().getStatementHistoryUrl(
    account,
    params,
  );

  return IgHttp.getInstance<IgHttp>().getWithCstAndXst<CostAndChargesHistoryResponse>(
    clientType,
    fullUrl,
    account.id,
  );
};

const getCostAndChargesPDF = (
  account: Account,
  indicativeQuoteReference: string,
): Promise<void> => {
  const igHttp: IgHttp = IgHttp.getInstance();
  const urlUtils: UrlUtils = UrlUtils.getInstance();
  const downloadUrl = urlUtils.getCostAndChargesPDFGatewayUrl(
    account,
    indicativeQuoteReference,
  );
  const filename = `${account.selectedProxyAccountId || account.id}-cost-and-charges-${indicativeQuoteReference}.pdf`;

  return igHttp
    .getWithCstAndXst<IFilterData>(account.clientType, downloadUrl, account.id)
    .then((data) => {
      urlUtils.getFile(data, filename);
    })
    .catch((err) => err);
};

export const StatementsService = {
  downloadStatement,
  getCostAndChargesPDF,
  getExAnteStatements,
  getStatements,
};
