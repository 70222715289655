import {
  AccountFactory,
  BasicInfoService,
  Client,
  ClientAccountDetailsService,
  Account,
  ReportTypesEnum,
} from "@cet/services";
import {
  ClientType,
  IClientData,
  IClientServiceResponse,
} from "@cet/interfaces";

export interface IClientAccountService {
  loadClientAndAccounts: (
    cst: string,
    clientType: ClientType,
    accountId?: string,
  ) => Promise<void>;
  getClientData: (clientType: ClientType) => IClientData;
  getClient: (clientType: ClientType) => Client;
  getAccount: (accountId: string, clientType: ClientType) => Account;
  getAccounts: (clientType: ClientType) => Account[];
}
const clientData: {
  demo: Client;
  live: Client;
} = {
  demo: undefined,
  live: undefined,
};

const fetchClientAccountDetails = (
  cst: string,
  clientType: ClientType,
  accountId?: string,
): Promise<IClientServiceResponse> => {
  const basicInfoService = BasicInfoService.getInstance<BasicInfoService>();
  const clientAccountDetailsService =
    ClientAccountDetailsService.getInstance<ClientAccountDetailsService>();
  const clientContext = basicInfoService.getClientContext(clientType);

  return clientAccountDetailsService.getClientDetails({
    accountId,
    clientContext,
    forceRefresh: true,
  });
};

const loadClientAndAccounts = (
  cst: string,
  clientType: ClientType,
  accountId?: string,
): Promise<void> => {
  const basicInfoService = BasicInfoService.getInstance<BasicInfoService>();
  const clientContext = basicInfoService.getClientContext(clientType);
  const accountFactory = AccountFactory.getInstance<AccountFactory>();
  const reportTypesEnum = ReportTypesEnum.getInstance<ReportTypesEnum>();
  return fetchClientAccountDetails(cst, clientType, accountId).then(
    (result: IClientServiceResponse) => {
      clientData[clientType] = new Client(
        accountFactory,
        result,
        reportTypesEnum,
        clientContext,
      );
    },
  );
};

const getClient = (clientType: ClientType): Client => clientData[clientType];

const getClientData = (clientType: ClientType): IClientData =>
  clientData[clientType].clientData;

const getAccount = (
  selectedAccountId: string,
  clientType: ClientType,
): Account => clientData[clientType].getAccountById(selectedAccountId);

const getAccounts = (clientType: ClientType): Account[] =>
  clientData[clientType].accounts;

export default {
  getAccount,
  getAccounts,
  getClient,
  getClientData,
  loadClientAndAccounts,
} as IClientAccountService;
