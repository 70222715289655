import React from "react";
import { t } from "@cet/services";
import {
  Button,
  ResponsiveTable,
  TableHeader,
  TableRow,
} from "@cet/components";
import { JapanTaxInvoiceResponseDto } from "@cet/interfaces";
import EligibleInvoiceNotificationBar from "./EligibleInvoiceNotificationBar";
import { useDownloadInvoice } from "../../../hooks/useDownloadInvoice";

type EligibleInvoiceTableProps = {
  invoices: JapanTaxInvoiceResponseDto[];
};

const EligibleInvoiceTable = ({ invoices }: EligibleInvoiceTableProps) => {
  const [
    { downloadAllInvoices, downloadSingleTaxInvoice, resetStatus },
    { status: downloadStatus },
  ] = useDownloadInvoice();

  const HEADERS: TableHeader[] = [
    { content: t("Statements.EligibleInvoice.yearAndMonth") },
    { content: t("Statements.EligibleInvoice.creationDate") },
    {
      content: (
        <Button
          variant="Primary"
          onClick={() =>
            downloadAllInvoices(invoices.map((el) => el.statementId))
          }
        >
          {t("Statements.EligibleInvoice.downloadAll")}（{invoices.length}）
        </Button>
      ),
      justifyContent: "flex-end",
    },
  ];

  const dtoToRow = (invoice: JapanTaxInvoiceResponseDto): TableRow => ({
    columns: [{ content: invoice.title }, { content: invoice.date }],
    id: invoice.statementId,
  });

  const rows: TableRow[] = invoices.map(dtoToRow);

  return (
    <>
      {!!downloadStatus && (
        <EligibleInvoiceNotificationBar
          status={downloadStatus}
          onClose={resetStatus}
        />
      )}
      <ResponsiveTable
        headers={HEADERS}
        rowAction={{
          mobileButtonLabel: t("Statements.EligibleInvoice.download"),
          mobileButtonVariant: "Default",
          onRowClick: downloadSingleTaxInvoice,
          disableDesktopRowClick: true,
          showOnDesktop: true,
        }}
        rows={rows}
      />
    </>
  );
};

export default EligibleInvoiceTable;
