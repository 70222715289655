import { StatementsState, TabAction } from "./types";

export const statementsReducer = (
  state: StatementsState,
  action: TabAction,
): StatementsState => {
  switch (action.type) {
    case "CHANGE_TAB":
      return { ...state, currentTab: action.payload.tabId };
    case "RESET":
      return { ...action.payload };
    default:
      return {
        ...state,
        tabsStore: {
          ...state.tabsStore,
          [action.type]: {
            ...state.tabsStore[action.type],
            ...action.payload,
          },
        },
      };
  }
};
