import React, { FC } from "react";
import { Grid, Text } from "@cet/components";
import { isoStringToDate, timestampToLocaleDate } from "@cet/utils";
import { t } from "@cet/services";
import { useTheme } from "@emotion/react";
import { StatementResultRow } from "./index";
import { ReportType, SelectedPeriod } from "../store";
import { StatementsType } from "../types";
import { IStatementDto } from "../services";
import { useStatements } from "../hooks";

type ResultsSectionProps = {
  statements: IStatementDto[];
  selectedReport: SelectedPeriod | ReportType;
  onDownload: (statement: IStatementDto) => void;
  statementsType?: StatementsType;
};

export const tastyStatementTypes = [StatementsType.TASTYDAILY, StatementsType.TASTYMONTHLY, StatementsType.TASTYYEARLY];

export const ResultsSection: FC<ResultsSectionProps> = ({
  selectedReport,
  statements,
  statementsType,
  onDownload,
}) => {
  const { locale } = useStatements();
  const { palette } = useTheme();
  const formatDate = (date) => {
    const [year, month] = date.split("-");
    return new Date(year, month - 1).toLocaleDateString("en-US", { month: "long", year: "numeric" });
  };
  const formatStatementDate = (statement: IStatementDto): string => {
    if (statementsType === StatementsType.TASTYYEARLY) {
      if (statement.periodStart !== "-" || statement.periodEnd !== "-") {
        return timestampToLocaleDate(
          isoStringToDate(statement.periodEnd).getTime(),
          { locale },
        );
      }
    }
    return "-";
  };

  const labelFormatter = {
    [SelectedPeriod.daily]: (statement: IStatementDto): string =>
      timestampToLocaleDate(isoStringToDate(statement.periodEnd).getTime(), {
        locale,
      }),
    [SelectedPeriod.monthly]: (statement: IStatementDto): string =>
      timestampToLocaleDate(isoStringToDate(statement.periodStart).getTime(), {
        format: "MM/yyyy",
      }),
    [SelectedPeriod.quarterly]: (statement: IStatementDto): string => {
      const start = timestampToLocaleDate(
        isoStringToDate(statement.periodStart).getTime(),
        { format: "MM/yyyy" },
      );
      const end = timestampToLocaleDate(
        isoStringToDate(statement.periodEnd).getTime(),
        { format: "MM/yyyy" },
      );

      return `${start} - ${end}`;
    },
    [SelectedPeriod.annual]: (statement: IStatementDto): string => {
      if (statementsType === StatementsType.CC) {
        return timestampToLocaleDate(
          isoStringToDate(statement.periodEnd).getTime(),
          { format: "yyyy" },
        );
      }

      return timestampToLocaleDate(
        isoStringToDate(statement.periodStart).getTime(),
        { format: "yyyy" },
      );
    },

    [ReportType.transactional]: (statement: IStatementDto): string => {
      if (statementsType === StatementsType.TASTYDAILY) {
        return timestampToLocaleDate(
          isoStringToDate(statement.periodEnd).getTime(),
          { locale },
        );
      }
      return formatDate(statement.periodEnd);
    },

    [ReportType.costandcharges]: formatStatementDate,
    [ReportType.uktaxstatement]: formatStatementDate,
    [ReportType.ustaxstatement]: formatStatementDate,
  };
  
  const tastyType = statementsType === StatementsType.TASTYDAILY
    ? t("AnnualReports.productType")
    : statementsType === StatementsType.TASTYYEARLY
    && t("AnnualReports.period");

  return (
    <Grid.Col mb={4}>
      {selectedReport === ReportType.ustaxstatement && <Grid.Row m={3}>
        <Text color="grey" data-testid='statements.tasty.annual.report.us.description' variant='BodyMedium' >
          {t("statements.tasty.annual.report.us.description")}
        </Text>
      </Grid.Row>}
      <Grid.Row ml={3}>
        {statements.length > 0 && (
          <Grid.Cell display="flex" alignItems="center" minWidth="90px">
            <Text variant="Footnote" color={palette.secondary}>
              {tastyStatementTypes.includes(statementsType) ? t("AnnualReports.date") : t("AnnualReports.period")}
            </Text>
          </Grid.Cell>
        )}
        {tastyType && <Grid.Cell display="flex" alignItems="center">
          <Text textAlign="center" variant="Footnote" color={palette.secondary} ml="55px">
            {tastyType}
          </Text>
        </Grid.Cell>}
      </Grid.Row>
      {statements.map((el, index) => (
        <StatementResultRow
          onDownload={onDownload}
          labelFormatter={labelFormatter[selectedReport]}
          statement={el}
          key={index}
          showReportType={statementsType === StatementsType.TASTYDAILY || statementsType === StatementsType.TASTYYEARLY}
        />
      ))}
    </Grid.Col>
  );
};
