import React from "react";
import { t } from "@cet/services";
import styled from "@emotion/styled";
import { NotificationBar } from "@cet/components";
import { NotificationBarVariantType } from "@cet/constants";

const FloatNotificationBarWrapper = styled.div`
  position: absolute;
  top: 10%;
  width: 90%;
  max-width: 739px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999;
`;

export type EligibleInvoiceNotificationBarProps = {
  status: "error" | "success";
  onClose?: () => void;
};

const EligibleInvoiceNotificationBar = ({
  status,
  onClose,
}: EligibleInvoiceNotificationBarProps) => {
  const messageMap: Record<
    EligibleInvoiceNotificationBarProps["status"],
    { message: string; variant: NotificationBarVariantType }
  > = {
    success: {
      message: t("Statements.EligibleInvoice.message.downloadSuccess"),
      variant: "Success",
    },
    error: {
      message: t("Statements.EligibleInvoice.message.downloadFailed"),
      variant: "Error",
    },
  };

  const message = messageMap[status];
  return (
    <FloatNotificationBarWrapper data-testid="eligible-invoice-notification-bar">
      <NotificationBar
        message={message.message}
        variant={message.variant}
        onCloseCallback={onClose}
      />
    </FloatNotificationBarWrapper>
  );
};

export default EligibleInvoiceNotificationBar;
