import React, { VFC } from "react";
import { ResponsiveTable, TableHeader, TableRow } from "@cet/components";
import { t } from "@cet/services";
import { dateToString } from "@cet/utils";
import { ErrorType } from "@cet/interfaces";
import { CostAndChargesHistoryDto, StatementsService } from "../../services";
import { useStatements } from "../../hooks";
import { TabState } from "../../store";

interface Props {
  data: CostAndChargesHistoryDto[];
  setTabState: (tabState: TabState) => void;
}

const dtoToRow = (dto: CostAndChargesHistoryDto): TableRow => ({
  columns: [
    { content: t(`Statements.${dto.type.toLowerCase()}`) },
    {
      content: dateToString(
        new Date(`${dto.createdTimestamp}Z`),
        "dd/MM/yyyy HH:mm:ss",
      ),
    },
    { content: dto.direction },
    { content: dto.instrumentName },
  ],
  id: dto.indicativeQuoteReference,
});

export const ExAnteDataTable: VFC<Props> = ({ data, setTabState }) => {
  const { account } = useStatements();

  const HEADERS: TableHeader[] = [
    { content: t("AnnualReports.period") },
    { content: t("RecentActivity.TradeHistory.Headers.date") },
    {
      content: t("RecentActivity.TradeHistory.Headers.direction"),
      hideOnMobile: true,
    },
    {
      content: t("RecentActivity.TradeHistory.Headers.market"),
      hideOnMobile: true,
    },
  ];

  const ROWS: TableRow[] = data.map((dto) => dtoToRow(dto));

  const onRowClick = (id: string): void => {
    StatementsService.getCostAndChargesPDF(account, id).catch(() => {
      setTabState(ErrorType.ServerError);
    });
  };

  return (
    <ResponsiveTable
      headers={HEADERS}
      rowAction={{
        mobileButtonLabel: t("AnnualReports.download"),
        mobileButtonVariant: "Default",
        onRowClick,
        showOnDesktop: true,
      }}
      rows={ROWS}
    />
  );
};
