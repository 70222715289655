import React, { FC, useReducer, VFC } from "react";
import { FormRow, Grid, Select, Tab, Tabs } from "@cet/components";
import { ModuleState } from "@cet/interfaces";
import { Account, t } from "@cet/services";
import { IStatementsTab, StatementsType } from "../types";
import { getInitialState, statementsReducer } from "../store";
import { EmptyStateSection, StatementsContentWrapper } from "../components";

type StatementsTabsContainerProps = {
  account: Account;
  tabs: IStatementsTab[];
};

function getProxyAccountOptions(account: Account) {
  if (!account.proxyAccounts?.length) {
    return [];
  }

  return [
    {
      label: t("Transactions.POA.primary"),
      options: [
        {
          label: `${account.client.firstName?.charAt(0) || ""} ${account.client.lastName} (${account.id})`,
          value: account.id,
        },
      ],
    },
    {
      label: t("Transactions.POA.managed"),
      options: account.proxyAccounts.map((proxyAccount) => {
        const initial = proxyAccount.accountDetails.firstName?.charAt(0) || "";

        return {
          label: `${initial} ${proxyAccount.accountDetails.lastName} (${proxyAccount.proxyAccountId})`,
          value: proxyAccount.proxyAccountId,
        };
      }),
    },
  ];
}

export const StatementsTabsContainer: FC<StatementsTabsContainerProps> = ({
  account,
  tabs,
}) => {
  const [statementsState, dispatch] = useReducer(
    statementsReducer,
    { account, tabs },
    getInitialState,
  );
  const proxyAccountOptions = getProxyAccountOptions(account);
  const { currentTab, tabsStore } = statementsState;
  const stores = Object.values(tabsStore);

  return (
    <Grid.Col mt={[4]}>
      {!!proxyAccountOptions.length && (
        <Grid.Row flexDirection={["column", "row"]}>
          <Grid.Cell data-testid="poaAccountSelector">
            <FormRow
              labelText={t("Transactions.POA.account")}
              width={["100%", "320px"]}
              paddingRight={4}
            >
              <Select.Basic
                menuPlacement="bottom"
                defaultValue={proxyAccountOptions[0].options[0]}
                options={proxyAccountOptions}
                onChange={(option: { value: string }): void => {
                  const accountId = option.value;

                  account.selectedProxyAccountId =
                    accountId === account.id ? null : accountId;
                  dispatch({
                    type: "RESET",
                    payload: getInitialState({ account, currentTab, tabs }),
                  });
                }}
              />
            </FormRow>
          </Grid.Cell>
        </Grid.Row>
      )}
      <Tabs
        fullWidth={false}
        defaultTabId={currentTab}
        onTabChangeCallback={(tabId: StatementsType) =>
          dispatch({ type: "CHANGE_TAB", payload: { tabId } })
        }
        key={stores.length}
      >
        {stores.map((tabStore) => {
          const statementTab = tabStore.tab;
          const { tabContent, statementsType, label } = statementTab;

          if (
            [StatementsType.JTM, StatementsType.EXANTE].includes(statementsType)
          ) {
            const StatementContent: VFC = tabContent;

            return (
              <Tab
                label={label}
                key={`tab-${statementsType}`}
                tabId={statementsType}
              >
                <StatementContent />
              </Tab>
            );
          }

          const { tabState } = tabStore;

          return (
            <Tab
              label={statementTab.label}
              key={`tab-${statementsType}`}
              tabId={statementsType}
            >
              <StatementsContentWrapper
                statementsState={tabStore}
                statementsTab={statementTab}
                statementsType={statementsType}
                dispatcher={dispatch}
              />
              {tabState !== ModuleState.Success && (
                <EmptyStateSection tabState={tabState} statementType={statementsType}  />
              )}
            </Tab>
          );
        })}
      </Tabs>
    </Grid.Col>
  );
};
