import React, { FC, useState } from "react";
import {
  Button,
  DateRangeSelect,
  FormRow,
  Grid,
  Pagination,
  Select,
  Spinner,
  Text,
} from "@cet/components";
import { useTheme } from "@emotion/react";
import { dateToString, IPredefinedPeriods } from "@cet/utils";
import { MisTrackingEventsService, t } from "@cet/services";
import { ButtonVariant } from "@cet/constants";
import { ErrorType, IPageSize, ModuleState } from "@cet/interfaces";
import { useStatements } from "../../hooks";
import { TabState } from "../../store";
import { EmptyStateSection, ExAnteSearch } from "../index";
import {
  CostAndChargesHistoryResponse,
  StatementsService,
} from "../../services";
import { ExAnteDataTable } from "./ExAnteDataTable";
import { StatementsType } from "../../types";

type SelectItem = {
  label: string;
  value: string;
};

interface Props {
  initialLimit: Date;
  options: SelectItem[];
  periodList: IPredefinedPeriods[];
}

enum PageSizeLabels {
  ROWS_10 = "rows10",
  ROWS_25 = "rows25",
  ROWS_50 = "rows50",
  ROWS_100 = "rows100",
}

const PageSizeValues = {
  [PageSizeLabels.ROWS_10]: "10",
  [PageSizeLabels.ROWS_25]: "25",
  [PageSizeLabels.ROWS_50]: "50",
  [PageSizeLabels.ROWS_100]: "100",
};

export const ExAnteStatementFormSection: FC<Props> = ({
  initialLimit,
  options,
  periodList,
}) => {
  const { account } = useStatements();
  const { palette } = useTheme();
  const misTrackingEventsService =
    MisTrackingEventsService.getInstance<MisTrackingEventsService>();
  const [tabState, setTabState] = useState<TabState>(ModuleState.Initial);
  const [selectedType, setSelectedType] = useState<SelectItem>(options[0]);
  const [instrumentId, setInstrumentId] = useState<string>(undefined);
  const [statementsData, setStatementsData] =
    useState<CostAndChargesHistoryResponse>(undefined);
  const [predefinedPeriod, setPredefinedPeriod] = useState<IPredefinedPeriods>(
    periodList[0],
  );
  const pageSizeList: IPageSize[] = Object.values(PageSizeLabels).map(
    (pageSizeLabel): IPageSize => ({
      label: t(`ClientPerformanceAnalytics.Pagination.${pageSizeLabel}`),
      value: PageSizeValues[pageSizeLabel],
    }),
  );

  const onPeriodChange = (period: SelectItem): void => {
    setSelectedType(period);
  };

  const handleDateSet = (selected: IPredefinedPeriods): void => {
    setPredefinedPeriod(selected);
  };

  const handleDateSelect = (selected: IPredefinedPeriods): void => {
    setPredefinedPeriod(selected);
  };

  const fetchStatements = (pageNumber: number, pageSize: number): void => {
    const trackId = `show${StatementsType.EXANTE}Statement`;

    misTrackingEventsService.trackClick(trackId);
    const from = dateToString(
      predefinedPeriod.startDate,
      "yyyy-MM-dd'T'HH:mm:ss'Z'",
    );
    const to = dateToString(
      predefinedPeriod.endDate,
      "yyyy-MM-dd'T'HH:mm:ss'Z'",
    );

    setTabState(ModuleState.Loading);
    StatementsService.getExAnteStatements(account, {
      from,
      ...(instrumentId && { instrumentId }),
      to,
      pageNumber: pageNumber - 1,
      pageSize,
      type: selectedType.value,
    })
      .then((result) => {
        if (result.pagination.totalElements === 0) {
          setTabState(ErrorType.NoDataForPeriod);
        } else {
          setStatementsData(result);
          setTabState(ModuleState.Success);
        }
      })
      .catch(() => {
        setTabState(ErrorType.ServerError);
      });
  };

  return (
    <>
      <Grid.Col>
        <Grid.Row>
          <Text variant="BodyMedium" mb={4} data-testid="exAnteDescription">
            {t("Statements.ExAnte.description")}
          </Text>
        </Grid.Row>
        {options && (
          <>
            <Grid.Row flexDirection={["column", "row"]} flexWrap="wrap">
              <Grid.Cell>
                <FormRow
                  labelText={t("AnnualReports.period")}
                  paddingRight={4}
                  minWidth={["100%", "auto"]}
                >
                  <Select.Basic
                    defaultValue={selectedType}
                    options={options}
                    onChange={onPeriodChange}
                    menuPlacement="bottom"
                    type="basicWide"
                  />
                </FormRow>
              </Grid.Cell>
              <Grid.Cell>
                <FormRow
                  labelText={t("Transactions.Headers.Date")}
                  paddingRight={4}
                  minWidth={["100%", "auto"]}
                >
                  <DateRangeSelect
                    size="normal"
                    handleSetCallback={handleDateSet}
                    handleSelectCallback={handleDateSelect}
                    datePickerLabels={{
                      cancel: t("ClientPerformanceAnalytics.Datepicker.cancel"),
                      custom: t(
                        "ClientPerformanceAnalytics.PredefinedPeriods.custom",
                      ),
                      set: t("ClientPerformanceAnalytics.Datepicker.set"),
                    }}
                    initialLimit={initialLimit}
                    locale={account.locale}
                    predefinedPeriodsList={periodList}
                    initialPredefinedPeriod={predefinedPeriod}
                  />
                </FormRow>
              </Grid.Cell>
            </Grid.Row>
            <Grid.Row flexDirection={["column", "row"]} flexWrap="wrap">
              <Grid.Cell minWidth={["100%", "auto"]}>
                <ExAnteSearch
                  account={account}
                  setInstrumentId={setInstrumentId}
                />
              </Grid.Cell>
            </Grid.Row>
          </>
        )}
        <Grid.Cell>
          <Grid.Row mb={4} minWidth={["100%", "auto"]}>
            <Button
              variant={ButtonVariant.Primary}
              data-testid="listPreviousStatements"
              onClick={(): void => fetchStatements(1, 10)}
            >
              {tabState === ModuleState.Loading && (
                <Spinner size={20} fill={palette.white} inline />
              )}
              {tabState !== ModuleState.Loading && t("Statements.Button.show")}
            </Button>
          </Grid.Row>
        </Grid.Cell>
      </Grid.Col>
      {tabState !== ModuleState.Success && (
        <EmptyStateSection tabState={tabState} />
      )}
      {tabState === ModuleState.Success && statementsData && (
        <>
          <ExAnteDataTable
            data={statementsData.costsAndChargesHistory}
            setTabState={setTabState}
          />
          <Pagination
            changePage={fetchStatements}
            pageSize={statementsData.pagination.pageSize}
            pageNumber={statementsData.pagination.pageNumber + 1}
            totalEntries={statementsData.pagination.totalElements}
            paginationLabels={{
              description1: t(
                "ClientPerformanceAnalytics.Pagination.description1",
              ),
              description2: t(
                "ClientPerformanceAnalytics.Pagination.description2",
              ),
              pageSizeList,
            }}
          />
        </>
      )}
    </>
  );
};
