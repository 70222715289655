import React, { FC } from "react";
import { Grid } from "@cet/components";
import { t } from "@cet/services";
import { ModuleState } from "@cet/interfaces";
import {
  ResultsSection,
  UsDisclosureInfo,
} from "../components";
import {
  TabContentProps,
} from "../types";
import { TastyFormSection } from "../components/TastyFormSection";

export const TastyTabContent: FC<TabContentProps> = ({ statements,
  onSubmit,
  onDownload,
  options,
  selectedOption,
  statementsType,
  tabState,
  showDisclosure = false }) => {
  const searchOptions = options.map((option) => ({
    label: t(`Statements.${option}`),
    value: option,
  }));

  return (
    <Grid.Col mt={4}>
      <TastyFormSection
        periods={searchOptions}
        selectedReport={searchOptions.find((el) => (el.value === selectedOption))}
        onSubmit={onSubmit}
        tabState={tabState}
      />

      {tabState === ModuleState.Success && <ResultsSection selectedReport={selectedOption}
        statements={statements && statements[selectedOption]}
        statementsType={statementsType}
        onDownload={onDownload} />}
      {showDisclosure && <UsDisclosureInfo />}
    </Grid.Col>
  );
};
