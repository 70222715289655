import { Account, IgHttp, UrlBuilder } from "@cet/services";
import { InstrumentsDto } from "./types";

const X_DEVICE_USER_AGENT_HEADER_VALUE =
  "vendor=IG Group | applicationType=ig | platform=MyIG | version=1.0.0";

const getInstruments = (
  account: Account,
  searchTerm: string,
  pageSize?: number,
): Promise<InstrumentsDto> => {
  const { clientType, id } = account;

  const fields = "id,underlyingId,underlyingName";

  const url = UrlBuilder.getInstance<UrlBuilder>().buildWithParams(
    "findergateway/v2/instruments",
    { fields, pageSize, searchTerm },
  );

  const fullUrl = UrlBuilder.getInstance<UrlBuilder>().buildDealingRestUrl(
    clientType,
    url,
  );

  const config = {
    headers: {
      "IG-ACCOUNT-ID": id,
      "x-device-user-agent": X_DEVICE_USER_AGENT_HEADER_VALUE,
    },
  };

  return IgHttp.getInstance<IgHttp>().getWithCst<InstrumentsDto>(
    clientType,
    fullUrl,
    config,
  );
};

export const FinderService = {
  getInstruments,
};
