import React, { FC, useState } from "react";
import { FormRow, OptionType, Search, Text } from "@cet/components";
import { Account, t } from "@cet/services";
import { Colors } from "@cet/constants";
import { FinderService, InstrumentDto } from "../../services";

type ExAnteSearchProps = {
  account: Account;
  setInstrumentId: (id: string) => void;
};

export const ExAnteSearch: FC<ExAnteSearchProps> = ({
  account,
  setInstrumentId,
}) => {
  const [error, setError] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const loadOptions = (query: string): Promise<OptionType[]> => {
    setError(false);
    if (query.length < 3) {
      return Promise.resolve([]);
    }

    const markets: OptionType[] = [];

    return FinderService.getInstruments(account, query, 20).then(
      (res) => {
        const instruments: InstrumentDto[] = res?.results?.instruments?.filter(
          (instrument) =>
            instrument.underlyingName
              .toLowerCase()
              .includes(query.toLowerCase()),
        );

        instruments.forEach((instrument: InstrumentDto) =>
          markets.push({
            label: `${instrument.underlyingName} (${instrument.id})`,
            value: instrument,
          }),
        );
        return markets;
      },
      () => {
        setError(true);
        return Promise.resolve([]);
      },
    );
  };

  const handleLoadingMessage = (obj: { inputValue: string }): string | null => {
    if (obj.inputValue?.length > 2) {
      return t("Statements.ExAnte.SearchComponent.LoadingMessage");
    }

    return null;
  };

  const handleNoOptionsMessage = (obj: {
    inputValue: string;
  }): string | null => {
    if (obj.inputValue?.length > 2) {
      if (!error)
        return t("Statements.ExAnte.SearchComponent.NoMarketsMessage");
      return null;
    }

    return t("Statements.ExAnte.SearchComponent.TipMessage");
  };

  const handleChange = (option: OptionType): void => {
    if (!option) {
      setInstrumentId("");
      setInputValue("");
      return;
    }

    setInputValue(option.label);
    setInstrumentId(option.value.id);
  };

  const onInputChange = (newInputValue, { action }) => {
    if (action === "input-change") {
      setInputValue(newInputValue);
    }
  };

  const marginBottomFn = (errorState: boolean): number[] | number => {
    if (errorState) {
      return [3, 2];
    }

    return 5;
  };

  return (
    <FormRow
      labelText={t("Statements.ExAnte.SearchComponent.Header")}
      marginBottom={marginBottomFn(error)}
    >
      <Search
        error={error}
        placeholder={t("Statements.ExAnte.SearchComponent.Placeholder")}
        loadingMessage={handleLoadingMessage}
        noOptionsMessage={handleNoOptionsMessage}
        loadOptions={loadOptions}
        inputValue={inputValue}
        onInputChange={onInputChange}
        onChange={handleChange}
      />
      {error && (
        <Text
          color={Colors.red}
          fontSize="13px"
          marginTop={2}
          lineHeight="20px"
        >
          {t("Statements.ExAnte.SearchComponent.Error")}{" "}
        </Text>
      )}
    </FormRow>
  );
};
