import { Grid } from "@cet/components";
import { t } from "@cet/services";
import React, { VFC } from "react";
import {
  calculatePeriodDates,
  IPredefinedPeriods,
  PredefinedPeriods,
} from "@cet/utils";
import { ExAnteStatementFormSection } from "../../components";
import { SelectedPeriod } from "../../store";

const initialLimit = new Date();

initialLimit.setFullYear(initialLimit.getFullYear() - 2);
const definedPeriods: PredefinedPeriods[] = [
  PredefinedPeriods.LAST_6_HOURS,
  PredefinedPeriods.LAST_12_HOURS,
  PredefinedPeriods.LAST_24_HOURS,
  PredefinedPeriods.LAST_3_DAYS,
  PredefinedPeriods.LAST_7_DAYS,
  PredefinedPeriods.LAST_30_DAYS,
  PredefinedPeriods.LAST_60_DAYS,
  PredefinedPeriods.LAST_90_DAYS,
  PredefinedPeriods.CUSTOM,
];

export const ExAnteTabContent: VFC = () => {
  const searchOptions = [
    SelectedPeriod.all,
    SelectedPeriod.preview,
    SelectedPeriod.pretrade,
  ].map((option) => ({
    label: t(`Statements.${option.toLowerCase()}`),
    value: option,
  }));

  const periodList: IPredefinedPeriods[] = definedPeriods.map((period) => ({
    endDate: calculatePeriodDates(new Date(), period, initialLimit).endDate,
    label: t(`ClientPerformanceAnalytics.PredefinedPeriods.${period}`),
    startDate: calculatePeriodDates(new Date(), period, initialLimit).startDate,
    value: period,
  }));

  return (
    <Grid.Col mt={4}>
      <ExAnteStatementFormSection
        initialLimit={initialLimit}
        options={searchOptions}
        periodList={periodList}
      />
    </Grid.Col>
  );
};
