import React, { FC } from "react";
import { Grid, Text } from "@cet/components";
import { t } from "@cet/services";
import { useTheme } from "@emotion/react";

export const UsDisclosureInfo: FC = () => {
  const { palette } = useTheme();

  return (
    <Grid.Row mt={4} alignItems="start" flexDirection="row">
      <Grid.Cell>
        <Text
          variant="BodyMedium"
          color={palette.grey500}
          dangerouslySetInnerHTML={{
            __html: t("Statements.Link.getUSDisclosuresAndRisk"),
          }}
        />
      </Grid.Cell>
    </Grid.Row>
  );
};
