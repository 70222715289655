import React, { FC, useState } from "react";
import { ModuleState } from "@cet/interfaces";
import {
  Button,
  DateRangeSelect,
  FormRow,
  Grid,
  Select,
  Spinner,
  Text,
} from "@cet/components";
import { ButtonVariant } from "@cet/constants";
import { t } from "@cet/services";
import { useTheme } from "@emotion/react";
import {
  calculatePeriodDates,
  IPredefinedPeriods,
  PredefinedPeriods,
} from "@cet/utils";
import { TabState } from "../store";
import { useStatements } from "../hooks";

type StatementsFormSectionProps = {
  title?: string;
  description: string;
  onSubmit: (period: string, startDate: Date, endDate: Date) => void;
  tabState?: TabState;
  periods?: { label: string; value: string }[];
  selectedReport?: { label: string; value: string };
};

const initialLimit = new Date();

initialLimit.setFullYear(initialLimit.getFullYear() - 2);

export const StatementsFormSection: FC<StatementsFormSectionProps> = ({
  title,
  description,
  periods,
  selectedReport,
  tabState,
  onSubmit,
}) => {
  const { account } = useStatements();
  const { palette } = useTheme();
  const [selected, setSelected] = useState(selectedReport);
  const periodList: IPredefinedPeriods[] = [
    {
      endDate: calculatePeriodDates(
        new Date(),
        PredefinedPeriods.LAST_90_DAYS,
        initialLimit,
      ).endDate,
      label: t(
        `ClientPerformanceAnalytics.PredefinedPeriods.${PredefinedPeriods.LAST_90_DAYS}`,
      ),
      startDate: calculatePeriodDates(
        new Date(),
        PredefinedPeriods.LAST_90_DAYS,
        initialLimit,
      ).startDate,
      value: PredefinedPeriods.LAST_90_DAYS,
    },
    {
      endDate: calculatePeriodDates(
        new Date(),
        PredefinedPeriods.CUSTOM,
        initialLimit,
      ).endDate,
      label: t(
        `ClientPerformanceAnalytics.PredefinedPeriods.${PredefinedPeriods.CUSTOM}`,
      ),
      startDate: calculatePeriodDates(
        new Date(),
        PredefinedPeriods.CUSTOM,
        initialLimit,
      ).startDate,
      value: PredefinedPeriods.CUSTOM,
    },
  ];
  const [predefinedPeriod, setPredefinedPeriod] = useState<IPredefinedPeriods>(
    periodList[0],
  );

  const onPeriodChange = (period): void => {
    setSelected(period);
  };

  const handleDateSet = (selectedDateSet: IPredefinedPeriods): void => {
    setPredefinedPeriod(selectedDateSet);
  };

  const handleDateSelect = (selectedDateSelect: IPredefinedPeriods): void => {
    setPredefinedPeriod(selectedDateSelect);
  };

  return (
    <Grid.Col>
      {title && (
        <Grid.Row mb={4}>
          <Text data-testid="Statements.Title.previousStatements" variant="H5">
            {title}
          </Text>
        </Grid.Row>
      )}
      <Grid.Row>
        <Text variant="BodyMedium" mb={4} data-testid="styledValue">
          {description}
        </Text>
      </Grid.Row>
      {periods && (
        <Grid.Row flexDirection={["column", "row"]}>
          <Grid.Cell>
            <FormRow
              labelText={t("AnnualReports.period")}
              minWidth={["100%", "320px"]}
              paddingRight={4}
            >
              <Select.Basic
                defaultValue={selected}
                options={periods}
                onChange={onPeriodChange}
              />
            </FormRow>
          </Grid.Cell>
          <Grid.Cell>
            <FormRow
              labelText={t("Transactions.Headers.Date")}
              minWidth={["100%", "320px"]}
            >
              <DateRangeSelect
                size="normal"
                handleSetCallback={handleDateSet}
                handleSelectCallback={handleDateSelect}
                datePickerLabels={{
                  cancel: t("ClientPerformanceAnalytics.Datepicker.cancel"),
                  custom: t(
                    "ClientPerformanceAnalytics.PredefinedPeriods.custom",
                  ),
                  set: t("ClientPerformanceAnalytics.Datepicker.set"),
                }}
                initialLimit={initialLimit}
                locale={account.locale}
                predefinedPeriodsList={periodList}
                initialPredefinedPeriod={predefinedPeriod}
              />
            </FormRow>
          </Grid.Cell>
        </Grid.Row>
      )}
      <Grid.Row mb={4}>
        <Button
          variant={ButtonVariant.Primary}
          onClick={(): void =>
            onSubmit(
              selected.value,
              predefinedPeriod.startDate,
              predefinedPeriod.endDate,
            )
          }
          data-testid="listPreviousStatements"
        >
          {tabState === ModuleState.Loading && (
            <Spinner size={20} fill={palette.white} inline />
          )}
          {tabState !== ModuleState.Loading && t("Statements.Button.show")}
        </Button>
      </Grid.Row>
    </Grid.Col>
  );
};
