import React, { FC, ReactElement } from "react";
import { Grid, Separator } from "@cet/components";
import { t } from "@cet/services";
import { ErrorType, ModuleState } from "@cet/interfaces";
import { StatementsEmptyState, tastyStatementTypes } from "./index";
import { TabState } from "../store";
import { StatementsType } from "../types";

type EmptyStateSection = {
  tabState: TabState;
  statementType?: StatementsType;
};

export const EmptyStateSection: FC<EmptyStateSection> = ({ tabState, statementType }) => (
  <Grid.Col>
    <Separator />
    <Grid.Row>
      {((): ReactElement => {
        switch (tabState) {
          case ModuleState.Initial:
            return (
              <StatementsEmptyState
                title={t("Statements.Message.Initial.Title")}
                description={ tastyStatementTypes.includes(statementType) ? t("Statements.Message.tastyInitial.Description") : t("Statements.Message.Initial.Description")}
              />
            );
          case ModuleState.Loading:
            return (
              <StatementsEmptyState
                description={t("Statements.Message.Loading.Description")}
              />
            );
          case ErrorType.NoData:
            return (
              <StatementsEmptyState
                title={t("Statements.Message.NoData.Title")}
                description={t("Statements.Message.NoData.Description")}
              />
            );
          case ErrorType.NoDataForPeriod:
            return (
              <StatementsEmptyState
                title={t("Statements.Message.NoData.Title")}
                description={t("Statements.Message.NoPreviousData.Description")}
              />
            );
          case ErrorType.ServerError:
          default:
            return (
              <StatementsEmptyState
                description={t("Statements.Message.Error.Description")}
              />
            );
        }
      })()}
    </Grid.Row>
  </Grid.Col>
);
