import React, {
  FC,
  useState,
} from "react";
import { ModuleState } from "@cet/interfaces";
import {
  Button,
  FormRow,
  Grid,
  Select,
  Spinner,
} from "@cet/components";
import { ButtonVariant } from "@cet/constants";
import { t } from "@cet/services";
import { useTheme } from "@emotion/react";
import {
  calculatePeriodDates,
  IPredefinedPeriods,
  PredefinedPeriods,
} from "@cet/utils";
import { TabState } from "../store";

type TastyFormSectionProps = {
  onSubmit: (period: string, startDate: Date, endDate: Date) => void;
  tabState?: TabState;
  periods?: { label: string; value: string }[];
  selectedReport?: { label: string; value: string };
};

const initialLimit = new Date();

initialLimit.setFullYear(initialLimit.getFullYear() - 2);

export const TastyFormSection: FC<TastyFormSectionProps> = ({
  periods,
  selectedReport,
  tabState,
  onSubmit,
}) => {
  const { palette } = useTheme();
  const [selected, setSelected] = useState(selectedReport);

  const periodList: IPredefinedPeriods[] = [
    {
      endDate: calculatePeriodDates(new Date(), PredefinedPeriods.LAST_90_DAYS, initialLimit).endDate,
      label: t(`ClientPerformanceAnalytics.PredefinedPeriods.${PredefinedPeriods.LAST_90_DAYS}`),
      startDate: calculatePeriodDates(new Date(), PredefinedPeriods.LAST_90_DAYS, initialLimit).startDate,
      value: PredefinedPeriods.LAST_90_DAYS,
    },
    {
      endDate: calculatePeriodDates(new Date(), PredefinedPeriods.CUSTOM, initialLimit).endDate,
      label: t(`ClientPerformanceAnalytics.PredefinedPeriods.${PredefinedPeriods.CUSTOM}`),
      startDate: calculatePeriodDates(new Date(), PredefinedPeriods.CUSTOM, initialLimit).startDate,
      value: PredefinedPeriods.CUSTOM,
    },
  ];
  const [predefinedPeriod] = useState<IPredefinedPeriods>(periodList[0]);

  const onPeriodChange = (period): void => {
    setSelected(period);
  };

  return (
    <Grid.Col>
      {periods && <Grid.Row flexDirection={["column", "row"]}>
        <Grid.Cell>
          <FormRow labelText={t("AnnualReports.period")}
            minWidth={["100%", "320px"]}
            mt={1}
            paddingRight={4}>
            <Select.Basic defaultValue={selected} menuPlacement='bottom' options={periods} onChange={onPeriodChange} />
          </FormRow>
        </Grid.Cell>
        <Grid.Cell mt={[5]}>
          <Button p={2} variant={ButtonVariant.Primary}
            onClick={(): void => onSubmit(selected.value, predefinedPeriod.startDate, predefinedPeriod.endDate)}
            data-testid='listPreviousStatements'>
            {tabState === ModuleState.Loading && <Spinner size={20} fill={palette.white} inline />}
            {tabState !== ModuleState.Loading && t("Statements.Button.show")}
          </Button>
        </Grid.Cell>
      </Grid.Row>}
    </Grid.Col>
  );
};
