import React, { FC } from "react";
import { Grid, Text } from "@cet/components";
import { t } from "@cet/services";
import { Adobe } from "@cet/icons";
import { useTheme } from "@emotion/react";

export const AdobeInfo: FC = () => {
  const { palette } = useTheme();

  return (
    <Grid.Row
      mt={4}
      mb={4}
      pl={[3, 4]}
      pr={[3, 4]}
      alignItems={["center"]}
      flexDirection="row"
    >
      <Grid.Cell>
        <Adobe fill={palette.grey500} />
      </Grid.Cell>
      <Grid.Cell ml={3}>
        <Text
          variant="BodyMedium"
          color={palette.grey500}
          dangerouslySetInnerHTML={{
            __html: t("Statements.Link.getAdobeReader"),
          }}
          data-testid="Statements.Link.getAdobeReader"
        />
      </Grid.Cell>
    </Grid.Row>
  );
};
