import React, { FC } from "react";
import { Grid } from "@cet/components";
import { t } from "@cet/services";
import { ModuleState } from "@cet/interfaces";
import { StatementsType, TabContentProps } from "../types";
import { ResultsSection, StatementsFormSection } from "../components";

export const CCTabContent: FC<TabContentProps> = ({
  statements,
  onSubmit,
  onDownload,
  options,
  selectedOption,
  tabState,
}) => {
  const searchOptions = options.map((option) => ({
    label: t(`Statements.${option}`),
    value: option,
  }));

  return (
    <Grid.Col mt={4}>
      <StatementsFormSection
        description={t("Statements.costsAndCharges.description")}
        periods={searchOptions}
        selectedReport={searchOptions.find((el) => el.value === selectedOption)}
        onSubmit={onSubmit}
        tabState={tabState}
      />
      {tabState === ModuleState.Success && (
        <ResultsSection
          selectedReport={selectedOption}
          statements={statements && statements[selectedOption]}
          statementsType={StatementsType.CC}
          onDownload={onDownload}
        />
      )}
    </Grid.Col>
  );
};
