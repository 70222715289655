import React, { useMemo, useState } from "react";
import { t, JapaneseTaxStatementService } from "@cet/services";
import { Grid, Text, Select, Checkbox, FormRow, Button } from "@cet/components";
import {
  ErrorType,
  JapanTaxInvoiceResponseDto,
  ModuleState,
} from "@cet/interfaces";
import { dateToString } from "@cet/utils";
import { EmptyStateSection } from "../../components";
import { TabState } from "../../store";
import Dash from "./components/Dash";
import EligibleInvoiceTable from "./components/EligibleInvoiceTable";
import { useStatements } from "../../hooks";

type SelectOption = { label: string; value: number };
type SelectedDate = { year: number | null; month: number | null };

type SelectHandler = (
  name: "year" | "month",
  range: "from" | "to",
  value: number,
) => void;

const generateNumbers = (
  from: number,
  amount: number,
  direction: "desc" | "asc" = "desc",
): number[] =>
  Array.from({ length: amount }).map((_el, index): number =>
    direction === "asc" ? from + index : from - index,
  );

const EligibleInvoice = () => {
  const { account } = useStatements();
  const [tabState, setTabState] = useState<TabState>(ModuleState.Initial);
  const [isRangeCheckboxSelected, toggleRangeCheckbox] = useState(false);
  const [dateRange, setDateRange] = useState<{
    from: SelectedDate;
    to: SelectedDate;
  }>({
    from: {
      year: null,
      month: null,
    },
    to: {
      year: null,
      month: null,
    },
  });
  const [taxInvoices, setTaxInvoices] = useState<JapanTaxInvoiceResponseDto[]>(
    [],
  );

  const fetchInvoices = async () => {
    setTabState(ModuleState.Loading);
    setTaxInvoices([]);

    const { from, to } = dateRange;

    const fromStringDate = dateToString(
      new Date(from.year, from.month - 1),
      "MM/yyyy",
    );

    const toStringDate = to.year
      ? dateToString(new Date(to.year, to.month - 1), "MM/yyyy")
      : fromStringDate;

    try {
      const response = await JapaneseTaxStatementService.fetchEligibleInvoices(
        account.id,
        fromStringDate,
        toStringDate,
      );

      if (!response || !response.length) {
        setTabState(ErrorType.NoData);
        return;
      }

      setTaxInvoices(response);

      setTabState(ModuleState.Success);
    } catch (error) {
      setTabState(ErrorType.ServerError);
    }
  };

  const onRangeCheckboxChange = (isChecked: boolean) => {
    toggleRangeCheckbox(isChecked);

    if (!isChecked) {
      setDateRange((prevState) => ({
        ...prevState,
        to: { year: null, month: null },
      }));
    }
  };

  const onSelectFieldHandler: SelectHandler = (name, range, value) => {
    setDateRange((prevState) => ({
      ...prevState,
      [range]: { ...prevState[range], [name]: value },
    }));
  };

  const isAllowToSubmit = useMemo(() => {
    const { from, to } = dateRange;
    if (!isRangeCheckboxSelected) {
      return Boolean(from.month && from.year);
    }
    return Boolean(from.month && from.year && to.month && to.year);
  }, [dateRange, isRangeCheckboxSelected]);

  return (
    <>
      <Grid.Col mt={4} data-testid="eligible-invoice">
        <Grid.Col mb={[3, 4]}>
          <Grid.Row mb={3}>
            <Text data-testid="eligible-invoice-description">
              {t("Statements.EligibleInvoice.description")}
            </Text>
          </Grid.Row>
          <Grid.Row minWidth="72px" alignItems="center" mb={3}>
            <Checkbox
              onChangeCallback={onRangeCheckboxChange}
              checked={isRangeCheckboxSelected}
            />
            <Text
              display="inline"
              data-testid="eligible-invoice-checkbox-description"
            >
              {t("Statements.EligibleInvoice.checkbox")}
            </Text>
          </Grid.Row>

          <Grid.Row
            flexDirection={["column", "row"]}
            alignItems={[null, "center"]}
          >
            <StartDateFields
              onSelect={onSelectFieldHandler}
              selectedDate={dateRange.from}
              isRangeCheckboxSelected={isRangeCheckboxSelected}
            />
            {isRangeCheckboxSelected && (
              <>
                <Dash />
                <EndDateFields
                  onSelect={onSelectFieldHandler}
                  selectedDate={dateRange.to}
                  fromDate={dateRange.from}
                />
              </>
            )}
          </Grid.Row>

          <Grid.Row>
            <Button
              data-testid="submit"
              onClick={fetchInvoices}
              variant={isAllowToSubmit ? "Primary" : "Unarmed"}
              mr={2}
              disabled={!isAllowToSubmit}
              minWidth="120px"
            >
              {t("Statements.EligibleInvoice.submit")}
            </Button>
          </Grid.Row>
        </Grid.Col>
      </Grid.Col>
      {tabState !== ModuleState.Success && (
        <EmptyStateSection tabState={tabState} />
      )}
      {tabState === ModuleState.Success && (
        <EligibleInvoiceTable invoices={taxInvoices} />
      )}
    </>
  );
};

const StartDateFields = ({
  onSelect,
  selectedDate,
  isRangeCheckboxSelected,
}: {
  onSelect: SelectHandler;
  selectedDate: SelectedDate;
  isRangeCheckboxSelected: boolean;
}) => {
  const yearsOptions = useMemo(() => {
    const currentYear = new Date().getFullYear();
    return generateNumbers(currentYear, 10).map(
      (number): SelectOption => ({
        label: `${number}${t("Statements.EligibleInvoice.year")}`,
        value: number,
      }),
    );
  }, []);

  const monthOptions = useMemo(
    () =>
      generateNumbers(1, 12, "asc").map(
        (number): SelectOption => ({
          label: `${number}${t("Statements.EligibleInvoice.month")}`,
          value: number,
        }),
      ),
    [],
  );

  return (
    <Grid.Row
      data-testid="start-date-fields-wrapper"
      alignItems="flex-end"
      maxWidth={["full", "320px"]}
      style={{ gap: "10px" }}
    >
      <Grid.Cell flex={[1, null]}>
        <FormRow
          labelText={t(
            isRangeCheckboxSelected
              ? "Statements.EligibleInvoice.startDate"
              : "Statements.EligibleInvoice.yearAndMonth",
          )}
          minWidth={["100%", "104px"]}
        >
          <Select.Basic
            data-testid="select-start-year"
            placeholder={t("Statements.EligibleInvoice.year")}
            defaultValue={selectedDate.year}
            options={yearsOptions}
            menuPlacement="bottom"
            onChange={(option: SelectOption) =>
              onSelect("year", "from", option.value)
            }
          />
        </FormRow>
      </Grid.Cell>
      <Grid.Cell flex={[1, null]}>
        <FormRow labelText="" minWidth={["100%", "160px"]}>
          <Select.Basic
            data-testid="select-start-month"
            placeholder={t("Statements.EligibleInvoice.month")}
            defaultValue={selectedDate.month}
            options={monthOptions}
            menuPlacement="bottom"
            onChange={(option: SelectOption) =>
              onSelect("month", "from", option.value)
            }
          />
        </FormRow>
      </Grid.Cell>
    </Grid.Row>
  );
};

const EndDateFields = ({
  selectedDate,
  onSelect,
  fromDate,
}: {
  selectedDate: SelectedDate;
  fromDate: SelectedDate;
  onSelect: SelectHandler;
}) => {
  const yearsOptions = useMemo(() => {
    const currentYear = new Date().getFullYear();
    return generateNumbers(currentYear, currentYear - fromDate.year + 1).map(
      (number): SelectOption => ({
        label: `${number}${t("Statements.EligibleInvoice.year")}`,
        value: number,
      }),
    );
  }, [fromDate.year]);

  const monthOptions = useMemo(() => {
    const isTheSameYear = selectedDate.year === fromDate.year;

    const from = isTheSameYear ? fromDate.month : 1;
    const to = isTheSameYear ? 12 - fromDate.month + 1 : 12;

    return generateNumbers(from, to, "asc").map(
      (number): SelectOption => ({
        label: `${number}${t("Statements.EligibleInvoice.month")}`,
        value: number,
      }),
    );
  }, [fromDate.year, selectedDate.year, fromDate.month]);

  return (
    <Grid.Row
      data-testid="end-date-fields-wrapper"
      alignItems="flex-end"
      maxWidth={["full", "320px"]}
      style={{ gap: "10px" }}
    >
      <Grid.Cell flex={[1, null]}>
        <FormRow
          labelText={t("Statements.EligibleInvoice.endDate")}
          minWidth={["100%", "104px"]}
        >
          <Select.Basic
            data-testid="select-end-year"
            placeholder={t("Statements.EligibleInvoice.year")}
            isDisabled={!fromDate.year}
            defaultValue={selectedDate.year}
            options={yearsOptions}
            menuPlacement="bottom"
            onChange={(option: SelectOption) =>
              onSelect("year", "to", option.value)
            }
          />
        </FormRow>
      </Grid.Cell>
      <Grid.Cell flex={[1, null]}>
        <FormRow labelText="" minWidth={["100%", "160px"]}>
          <Select.Basic
            data-testid="select-end-month"
            placeholder={t("Statements.EligibleInvoice.month")}
            isDisabled={!fromDate.year || !selectedDate.year}
            defaultValue={selectedDate.month}
            menuPlacement="bottom"
            options={monthOptions}
            onChange={(option: SelectOption) =>
              onSelect("month", "to", option.value)
            }
          />
        </FormRow>
      </Grid.Cell>
    </Grid.Row>
  );
};

export default EligibleInvoice;
