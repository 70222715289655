import React, { FC } from "react";
import { ModuleState } from "@cet/interfaces";
import { Grid, Separator } from "@cet/components";
import { t } from "@cet/services";
import {
  LeveragedStatementsCurrentSection,
  ResultsSection,
  StatementsFormSection,
  UsDisclosureInfo,
} from "../components";
import { TabContentProps } from "../types";
import { useStatements } from "../hooks";

export const LeveragedTabContent: FC<TabContentProps> = ({
  statements,
  dispatcher,
  onSubmit,
  onDownload,
  options,
  selectedOption,
  tabState,
  showDisclosure = false,
}) => {
  const { account } = useStatements();
  const searchOptions = options.map((option) => ({
    label: t(`Statements.${option}`),
    value: option,
  }));

  return (
    <Grid.Col mt={4}>
      {!account.isMTF && (
        <LeveragedStatementsCurrentSection dispatcher={dispatcher} />
      )}
      {!account.isMTF && <Separator mb={4} />}
      <StatementsFormSection
        title={t("Statements.Title.previous")}
        description={t("Statements.Description.previousStatements")}
        periods={searchOptions}
        selectedReport={searchOptions.find((el) => el.value === selectedOption)}
        onSubmit={onSubmit}
        tabState={tabState}
      />
      {tabState === ModuleState.Success && (
        <ResultsSection
          selectedReport={selectedOption}
          statements={statements && statements[selectedOption]}
          onDownload={onDownload}
        />
      )}
      {showDisclosure && <UsDisclosureInfo />}
    </Grid.Col>
  );
};
