import { ErrorType, ModuleState } from "@cet/interfaces";
import { Dispatch, FC } from "react";
import { ActionType, ReportType, SelectedPeriod, TabAction } from "./store";
import { IStatementDto } from "./services";

export type ENV = "LIVE" | "DEMO" | "UAT" | "TEST";

export enum StatementsType {
  LEVERAGED = "LEVERAGED",
  PHYSICAL = "PHYSICAL",
  INDUCEMENT = "INDUCEMENT",
  CC = "CC",
  EXANTE = "EXANTE",
  JTM = "JTM",
  TASTYDAILY = "TASTYDAILY",
  TASTYMONTHLY = "TASTYMONTHLY",
  TASTYYEARLY = "TASTYYEARLY",
  IFU  = "IFU"
}

export interface IStatements {
  annual?: IStatementDto[];
  daily?: IStatementDto[];
  monthly?: IStatementDto[];
  quarterly?: IStatementDto[];
  years?: string[];
  transactional?: IStatementDto[];
  costandcharges?: IStatementDto[];
  uktaxstatement?: IStatementDto[];
  ustaxstatement?: IStatementDto[];
}

export interface IStatementsTab {
  label: string;
  statementsType: StatementsType;
  actionType: ActionType;
  availableForPOA: boolean;
  tabContent: FC<TabContentProps>;
  options: SelectedPeriod[] | ReportType[];
  usDisclosure: boolean;
}

export type TabContentProps = {
  options: SelectedPeriod[] | ReportType[];
  selectedOption: SelectedPeriod | ReportType;
  statements: IStatements;
  showDisclosure?: boolean;
  statementsType: StatementsType;
  tabState: ModuleState | ErrorType;
  onDownload: (statement: IStatementDto) => void;
  onSubmit: (
    reportPeriod: SelectedPeriod,
    startDate: Date,
    endDate: Date,
  ) => void;
  dispatcher?: Dispatch<TabAction>;
};
