import { ErrorType, ModuleState } from "@cet/interfaces";
import { Account } from "@cet/services";
import { Dispatch } from "react";
import { IStatements, IStatementsTab, StatementsType } from "../types";

export type FetchStatementsDispatchParams = {
  account: Account;
  actionType: ActionType;
  dispatcher: Dispatch<TabAction>;
  selectedReport: SelectedPeriod | ReportType;
  statements?: IStatements;
  statementsType: StatementsType;
  tabState: TabState;
  fromDate: string;
  toDate: string;
};

export enum SelectedPeriod {
  annual = "annual",
  daily = "daily",
  monthly = "monthly",
  quarterly = "quarterly",
  all = "ALL",
  pretrade = "TRADE",
  preview = "PREVIEW",
}

export enum ReportType {
  transactional = "transactional",
  costandcharges = "costandcharges",
  uktaxstatement = "uktaxstatement",
  ustaxstatement = "ustaxstatement",
}

export type TabStore = {
  statements?: IStatements;
  selectedReport: SelectedPeriod | ReportType;
  tab: IStatementsTab;
  tabState: TabState;
};

export type TabsStore = {
  [key in StatementsType]?: TabStore;
};

export type StatementsState = {
  currentTab: StatementsType;
  tabsStore: TabsStore;
};

export enum ActionType {
  LEVERAGED = "LEVERAGED",
  PHYSICAL = "PHYSICAL",
  INDUCEMENT = "INDUCEMENT",
  CC = "CC",
  TASTYDAILY = "TASTYDAILY",
  TASTYMONTHLY = "TASTYMONTHLY",
  TASTYYEARLY = "TASTYYEARLY",
  IFU = "IFU"
}

export type TabAction =
  | { type: "CHANGE_TAB"; payload: { tabId: StatementsType } }
  | { type: "RESET"; payload: StatementsState }
  | {
      type: ActionType;
      payload: Partial<TabStore>;
    };

export type TabState = ModuleState | ErrorType;
