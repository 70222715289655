import React, { FC } from "react";
import { Grid, Text } from "@cet/components";
import { useTheme } from "@emotion/react";

type StatementsEmptyStateProps = {
  title?: string;
  description: string;
};

export const StatementsEmptyState: FC<StatementsEmptyStateProps> = ({
  title,
  description,
}) => {
  const { palette } = useTheme();

  return (
    <Grid.Row minHeight="240px" data-testid="emptyState">
      <Grid.Col alignItems="center" mt={6} textAlign={["center"]}>
        <Grid.Cell mb={3}>
          <Text variant="H4">{title}&nbsp;</Text>
        </Grid.Cell>
        <Grid.Cell>
          <Text color={palette.grey700}>{description}</Text>
        </Grid.Cell>
      </Grid.Col>
    </Grid.Row>
  );
};
