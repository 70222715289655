import { ModuleState } from "@cet/interfaces";
import { Account } from "@cet/services";
import { IStatementsTab, StatementsType } from "../types";
import { ReportType, SelectedPeriod, StatementsState, TabStore } from "./types";

export const getInitialState = ({
  account,
  currentTab,
  tabs,
}: {
  account: Account;
  currentTab?: StatementsType;
  tabs: IStatementsTab[];
}): StatementsState => {
  const selectedReport = (statementsType: StatementsType): SelectedPeriod | ReportType => {
    if (statementsType === StatementsType.CC) {
      return SelectedPeriod.annual;
    }
    if (statementsType === StatementsType.INDUCEMENT) {
      return SelectedPeriod.monthly;
    }
    if (statementsType === StatementsType.EXANTE) {
      return SelectedPeriod.all;
    }
    if (statementsType === StatementsType.TASTYDAILY) {
      return ReportType.transactional;
    } 
    if (statementsType === StatementsType.TASTYMONTHLY) {
      return ReportType.transactional;
    } 
    if (statementsType === StatementsType.TASTYYEARLY) {
      return ReportType.costandcharges;
    }
    if (statementsType === StatementsType.IFU) {
      return SelectedPeriod.annual;
    }

    return SelectedPeriod.daily;
  };

  const applicableTabs = account.selectedProxyAccountId
    ? tabs.filter((tab) => tab.availableForPOA)
    : tabs;

  const stores: TabStore[] = applicableTabs.map((tab: IStatementsTab) => ({
      selectedReport: selectedReport(tab.statementsType),
      statements: null,
      tab,
      tabState: ModuleState.Initial,
    }));

  return {
    currentTab:
      currentTab && stores.find(({ tab }) => tab.statementsType === currentTab)
        ? currentTab
        : stores[0].tab.statementsType,
    tabsStore: stores.reduce((acc, store) => {
      acc[store.tab.statementsType] = store;
      return acc;
    }, {}),
  };
};
