import React, { FC } from "react";
import { Button, Grid, Separator, Text } from "@cet/components";
import { t } from "@cet/services";
import { ButtonSizesVariant } from "@cet/constants";
import { IStatementDto } from "../services";

type StatementResultRowProps = {
  statement: IStatementDto;
  onDownload: (statement: IStatementDto) => void;
  labelFormatter: (statement: IStatementDto) => string;
  showReportType?: boolean;
};

export const StatementResultRow: FC<StatementResultRowProps> = ({
  statement,
  onDownload,
  labelFormatter,
  showReportType,
}) => (
  <>
    <Grid.Row
      my={3}
      justifyContent="space-between"
      data-testid="Statements.result.row"
    >
      <Grid.Cell display="flex" alignItems="center">
        <Text variant="H5" ml={3} margin={statement.periodStart === "-" && "0 50px"}>
          {labelFormatter(statement)}
        </Text>
      </Grid.Cell>
      {showReportType && (
        <Grid.Cell display="flex" alignItems="center" flex="1">
          <Text firstLetterUppercase variant="H5" ml="55px">
            {getTastyReportTypeDisplayValue(statement.reportType)}
          </Text>
        </Grid.Cell>
      )}
      <Grid.Cell display="flex" alignItems="center">
        <Button
          size={ButtonSizesVariant.Small}
          mr={3}
          data-testid="downloadStatement"
          onClick={() => onDownload(statement)}
        >
          {t("AnnualReports.download")}
        </Button>
      </Grid.Cell>
    </Grid.Row>
    <Separator />
  </>
);

const getTastyReportTypeDisplayValue = (reportType: string) => {
  switch (reportType.toUpperCase()) {
    case "CONFIRMATION":
      return t("statements.tasty.report.confirmations");
    case "FUTURES_STATEMENT":
      return t("statements.tasty.report.futures");
    case "COST_AND_CHARGES":
      return t("Statements.costandcharges")
    case "CONSOLIDATED_TAX_CERTIFICATE":
      return t("Statements.uktaxstatement")
    default:
      if (reportType.includes("irs_")) {
       return `${extractNumbersFromString(reportType)} ${t("Statements.ustaxstatement")}`;
      }
      return reportType;
  }
};

const extractNumbersFromString = (reportType: string) => {
  const regex = /\d+/g;
  const numbers = reportType.match(regex);
  return numbers;
}