import { useState } from "react";
import { JapaneseTaxStatementService } from "@cet/services";
import { useStatements } from "./useStatements";

type DownloadStatus = "success" | "error";

type Return = [
  {
    downloadSingleTaxInvoice: (statementId: string) => Promise<void>;
    downloadAllInvoices: (invoices: string[]) => Promise<void>;
    resetStatus: () => void;
  },
  { status: DownloadStatus },
];

export const useDownloadInvoice = (): Return => {
  const { account } = useStatements();
  const [downloadStatus, setDownloadStatus] = useState<DownloadStatus>();

  const resetStatus = () => {
    setDownloadStatus(undefined);
  };

  const downloadAllInvoices = async (statementIds: string[]) => {
    resetStatus();
    const promises = Promise.all(
      statementIds.map((statementId) =>
        JapaneseTaxStatementService.downloadTaxInvoice(account.id, statementId),
      ),
    );

    try {
      await promises;
      setDownloadStatus("success");
    } catch (error) {
      setDownloadStatus("error");
    } finally {
      setTimeout(() => {
        resetStatus();
      }, 5000);
    }
  };

  const downloadSingleTaxInvoice = async (statementId: string) => {
    resetStatus();
    try {
      await JapaneseTaxStatementService.downloadTaxInvoice(
        account.id,
        statementId,
      );
      setDownloadStatus("success");
    } catch (error) {
      setDownloadStatus("error");
    } finally {
      setTimeout(() => {
        resetStatus();
      }, 5000);
    }
  };

  return [
    { downloadSingleTaxInvoice, downloadAllInvoices, resetStatus },
    { status: downloadStatus },
  ];
};
