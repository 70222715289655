import { ReportType, SelectedPeriod } from "../store";
import { IStatements, StatementsType } from "../types";
import { IStatementDto } from "./types";

enum SortingDirection {
  ASCENDING = "asc",
  DESCENDING = "desc",
}

enum StatementReportType {
  DAILY = "DAILY",
  MONTHLY = "MONTHLY",
  QUARTERLY = "QUARTERLY",
  ANNUAL = "ANNUAL",
  INDUCEMENT = "INDUCEMENT",
  COST_AND_CHARGES = "COST_AND_CHARGES",
}

const TAX_CERTIFICATE = "Tax Certificate";
const YEAR_LENGTH = 4;

const sortByDate = (
  key,
  direction: SortingDirection,
): ((a: IStatementDto, b: IStatementDto) => number) => {
  if (direction === SortingDirection.DESCENDING) {
    return (a: IStatementDto, b: IStatementDto): number =>
      new Date(b[key]).getTime() - new Date(a[key]).getTime();
  }

  return (a: IStatementDto, b: IStatementDto): number =>
    new Date(a[key]).getTime() - new Date(b[key]).getTime();
};

const orderByDate = (
  statements: IStatementDto[],
  key: string,
  direction: SortingDirection,
): IStatementDto[] => statements.concat().sort(sortByDate(key, direction));

const buildPhysicalOrLeveragedStatements = (
  statements: IStatementDto[],
): IStatements => {
  const annual: IStatementDto[] = [];
  const daily: IStatementDto[] = [];
  const monthly: IStatementDto[] = [];
  const quarterly: IStatementDto[] = [];
  const yearsSet: Set<string> = new Set();

  statements.forEach((statement) => {
    const reportType =
      statement.reportType === TAX_CERTIFICATE
        ? StatementReportType.ANNUAL
        : statement.reportReference
            .split("_")
            .find((entry) => Object.keys(StatementReportType).includes(entry));

    if (reportType) {
      yearsSet.add(statement.periodStart.substr(0, YEAR_LENGTH));
    }

    switch (reportType) {
      case StatementReportType.ANNUAL:
        annual.push(statement);
        break;
      case StatementReportType.DAILY:
        daily.push(statement);
        break;
      case StatementReportType.MONTHLY:
        monthly.push(statement);
        break;
      case StatementReportType.QUARTERLY:
        quarterly.push(statement);
        break;
      default:
      // noop
    }
  });

  return {
    annual: orderByDate(annual, "periodStart", SortingDirection.DESCENDING),
    daily: orderByDate(daily, "periodEnd", SortingDirection.DESCENDING),
    monthly: orderByDate(monthly, "periodStart", SortingDirection.DESCENDING),
    quarterly: orderByDate(
      quarterly,
      "periodStart",
      SortingDirection.DESCENDING,
    ),
    years: Array.from(yearsSet).sort().reverse(),
  };
};

const buildCCStatements = (statements: IStatementDto[]): IStatements => {
  const annual: IStatementDto[] = statements.filter(
    (entry) => entry.reportType === StatementReportType.COST_AND_CHARGES,
  );
  const daily: IStatementDto[] = [];
  const monthly: IStatementDto[] = [];
  const quarterly: IStatementDto[] = [];
  const yearsSet: Set<string> = new Set();

  return {
    annual,
    daily,
    monthly,
    quarterly,
    years: Array.from(yearsSet).sort().reverse(),
  };
};

const buildInducementStatements = (
  statements: IStatementDto[],
): IStatements => {
  const annual: IStatementDto[] = [];
  const daily: IStatementDto[] = [];
  const monthly: IStatementDto[] = [];
  const quarterly: IStatementDto[] = [];
  const yearsSet: Set<string> = new Set();

  statements
    .filter((entry) => entry.reportType === StatementReportType.INDUCEMENT)
    .forEach((statement) => {
      yearsSet.add(new Date(statement.periodEnd).getFullYear().toString());
      monthly.push(statement);
    });

  return {
    annual,
    daily,
    monthly: orderByDate(monthly, "periodEnd", SortingDirection.DESCENDING),
    quarterly,
    years: Array.from(yearsSet).sort().reverse(),
  };
};

const buildTastyDailyStatements = (statements: IStatementDto[]): IStatements => {
  const transactional: IStatementDto[] = statements;
  const daily: IStatementDto[] = [];
  const monthly: IStatementDto[] = [];
  const quarterly: IStatementDto[] = [];
  const annual: IStatementDto[] = [];
  const yearsSet: Set<string> = new Set();

  return {
    transactional,
    annual,
    daily,
    monthly,
    quarterly,
    years: Array.from(yearsSet).sort().reverse(),
  };
};

const buildTastyMonthlyStatements = (statements: IStatementDto[]): IStatements => {
  const transactional: IStatementDto[] = statements;
  const daily: IStatementDto[] = [];
  const monthly: IStatementDto[] = [];
  const quarterly: IStatementDto[] = [];
  const annual: IStatementDto[] = [];
  const yearsSet: Set<string> = new Set();

  return {
    transactional,
    annual,
    daily,
    monthly,
    quarterly,
    years: Array.from(yearsSet).sort().reverse(),
  };
};


const buildTastyAnnualStatements = (statements: IStatementDto[], selectedReport : ReportType | SelectedPeriod ): IStatements => {

  const annual: IStatementDto[] = [];
  const daily: IStatementDto[] = [];
  const monthly: IStatementDto[] = [];
  const quarterly: IStatementDto[] = [];
  const uktaxstatement : IStatementDto[] = [];
  const ustaxstatement : IStatementDto[] = [];
  const costandcharges : IStatementDto[] = [];

  const yearsSet: Set<string> = new Set();
  switch (selectedReport) {
    case ReportType.costandcharges:
      statements.forEach(statement => costandcharges.push(statement));
      break;
    case  ReportType.uktaxstatement:
      statements.forEach(statement => uktaxstatement.push(statement));
      break;
    case  ReportType.ustaxstatement:
      statements.forEach(statement => ustaxstatement.push(statement));
      break;
    default:
  }
  return {
    annual,
    daily,
    monthly,
    quarterly,
    years: Array.from(yearsSet).sort().reverse(),
    uktaxstatement,
    ustaxstatement,
    costandcharges,
  };
};

const buildIFUStatements = (statements: IStatementDto[]): IStatements => {
  const annual: IStatementDto[] = [];

  statements.forEach(statement => annual.push(statement));

  return {
    annual,
  };
}

const buildStatementsModel = (
  statements: IStatementDto[],
  type: StatementsType,
  selectedReport?: ReportType | SelectedPeriod
): IStatements => {
  switch (type) {
    case StatementsType.LEVERAGED:
      return buildPhysicalOrLeveragedStatements(statements);
    case StatementsType.INDUCEMENT:
      return buildInducementStatements(statements);
    case StatementsType.CC:
      return buildCCStatements(statements);
    case StatementsType.TASTYDAILY:
      return buildTastyDailyStatements(statements);
    case StatementsType.TASTYMONTHLY:
      return buildTastyMonthlyStatements(statements);
    case StatementsType.TASTYYEARLY:
      return buildTastyAnnualStatements(statements, selectedReport);
    case StatementsType.IFU:
      return buildIFUStatements(statements);
    case StatementsType.PHYSICAL:
    default:
      return buildPhysicalOrLeveragedStatements(statements);
  }
};

export const StatementsFactory = {
  buildStatementsModel,
};
