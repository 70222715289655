import {
  BasicInfoService,
  fetchWhitelabelEndpoints,
  TranslationsService,
} from "@cet/services";
import {
  ClientType,
  ClientTypes,
  EndpointsUrlData,
  IBasicInfoData,
} from "@cet/interfaces";
import { getCookie } from "@cet/utils";
import ClientAccountService from "./ClientAccountService";

enum Error {
  Endpoints = "ERR_FETCH_END",
  BasicInfo = "ERR_FETCH_BASIC",
  Translation = "ERR_FETCH_TRANS",
  ClientDetails = "ERR_FETCH_DETAIL",
}

export interface IBootstrapService {
  runBootstrap: (
    accountId: string,
    env: string,
    cst: string,
    requestDomain: string,
    clientType: ClientType,
  ) => Promise<boolean | void>;
}

const runBootstrap = async (
  accountId: string,
  env: string,
  cst: string,
  requestDomain: string,
  clientType: ClientType,
): Promise<boolean | void> => {
  globalThis.MyIG = globalThis.MyIG || {};
  const basicInfoService = BasicInfoService.getInstance<BasicInfoService>();
  const urlData: EndpointsUrlData = {
    prefix: requestDomain === "" ? "/" : `${requestDomain}/`,
    queryString: getCookie("myig-cluster")
      ? `?myig_cluster=${getCookie("myig-cluster")}`
      : "",
  };

  try {
    const result = await fetchWhitelabelEndpoints(urlData, {});

    if (window.MyIG) {
      Object.assign(window.MyIG, { constants: result });
    }
  } catch (e) {
    return Promise.reject(Error.Endpoints);
  }

  let basicInfo: IBasicInfoData;

  try {
    basicInfo = await basicInfoService.forceReload(env, cst, {
      "IG-WL-CLIENT": "WL",
    });
  } catch (e) {
    return Promise.reject(Error.BasicInfo);
  }

  const translationsService =
    TranslationsService.getInstance<TranslationsService>();

  try {
    await translationsService.loadTranslations(basicInfo.translationsUrl);
  } catch (e) {
    return Promise.reject(Error.Translation);
  }

  try {
    if (basicInfo.hasActiveLiveAccounts && clientType === ClientTypes.LIVE) {
      await ClientAccountService.loadClientAndAccounts(
        basicInfo.liveCst,
        ClientTypes.LIVE,
        accountId,
      );
    }

    if (
      basicInfo.hasActiveDemoAccounts &&
      basicInfo.demoCst &&
      clientType === ClientTypes.DEMO
    ) {
      await ClientAccountService.loadClientAndAccounts(
        basicInfo.demoCst,
        ClientTypes.DEMO,
        accountId,
      );
    }
  } catch (e) {
    return Promise.reject(Error.ClientDetails);
  }

  return true;
};

export default {
  runBootstrap,
} as IBootstrapService;
