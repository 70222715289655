import React from "react";
import { Grid } from "@cet/components";

const Dash = () => (
  <Grid.Cell width="50px" display={["none", "flex"]} justifyContent="center">
    -
  </Grid.Cell>
);

export default Dash;
