import React, { FC, useEffect, useRef, useState } from "react"
import { Grid, Text } from "@cet/components"
import { t } from "@cet/services"
import { IPredefinedPeriods, PredefinedPeriods, calculatePeriodDates } from "@cet/utils"

type IFUProps = {
  onSubmit: (period: string, startDate: Date, endDate: Date) => void;
  selectedReport?: { label: string; value: string };
}
const initialLimit = new Date();

export const IFUStatemenstFormSection: FC<IFUProps> = ({ onSubmit, selectedReport }) => {

  const hasRunRef = useRef(false);

  const periodList: IPredefinedPeriods[] = [
    {
      endDate: calculatePeriodDates(new Date(), PredefinedPeriods.LAST_90_DAYS, initialLimit).endDate,
      label: t(`ClientPerformanceAnalytics.PredefinedPeriods.${PredefinedPeriods.LAST_90_DAYS}`),
      startDate: calculatePeriodDates(new Date(), PredefinedPeriods.LAST_90_DAYS, initialLimit).startDate,
      value: PredefinedPeriods.LAST_90_DAYS,
    },
    {
      endDate: calculatePeriodDates(new Date(), PredefinedPeriods.CUSTOM, initialLimit).endDate,
      label: t(`ClientPerformanceAnalytics.PredefinedPeriods.${PredefinedPeriods.CUSTOM}`),
      startDate: calculatePeriodDates(new Date(), PredefinedPeriods.CUSTOM, initialLimit).startDate,
      value: PredefinedPeriods.CUSTOM,
    },
  ];
  const [predefinedPeriod] = useState<IPredefinedPeriods>(periodList[0]);

  useEffect(() => {
    if (!hasRunRef.current) {
      onSubmit(selectedReport.value, predefinedPeriod.startDate, predefinedPeriod.endDate);
      hasRunRef.current = true;
    }
  }, [selectedReport, predefinedPeriod, onSubmit]);

  return (
    <Grid.Col>
      <Grid.Row>
        <Text data-testid="Statements.Title.IFU.previousStatements" mb={4} variant="H5">
          {t("Statements.Title.previous")}
        </Text>
      </Grid.Row>
      <Grid.Row>
        <Text color="grey" variant="BodyMedium" mb={4} data-testid="ifuDescription">
          {t("Statements.Description.previousStatements")}
        </Text>
      </Grid.Row>
    </Grid.Col>
  )
}
