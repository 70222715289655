import React from "react";
import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";
import { LifeCycleFn } from "single-spa";
import { Root, AppProps } from "./root.component";
import ClientAccountService from "./services/ClientAccountService";

const lifecycles = singleSpaReact<AppProps>({
  React,
  ReactDOM,
  rootComponent: Root,
  errorBoundary() {
    // Customize the root error boundary for your microfrontend here.
    return null;
  },
  renderType: "render",
});

export const { bootstrap, mount, update } = lifecycles;
export const unmount: LifeCycleFn<AppProps> = (props) => {
  const account = ClientAccountService.getAccount(props.accountId, props.clientType);
  account.selectedProxyAccountId = null;
  return lifecycles.unmount(props);
};
