import { Grid } from "@cet/components"
import React, { FC } from "react"
import { ModuleState } from "@cet/interfaces"
import { t } from "@cet/services"
import { ResultsSection, UsDisclosureInfo } from "../components"
import { IFUStatemenstFormSection } from "../components/IFUStatemenstFormSection"
import { TabContentProps } from "../types"

export const IFUTabContent: FC<TabContentProps> = ({ statements,
  onSubmit,
  onDownload,
  options,
  selectedOption,
  statementsType,
  tabState,
  showDisclosure = false }) => {
  const searchOptions = options.map((option) => ({
    label: t(`Statements.${option}`),
    value: option,
  }));
  return (
    <Grid.Col mt={4}>
      <IFUStatemenstFormSection
        selectedReport={searchOptions.find((el) => (el.value === selectedOption))}
        onSubmit={onSubmit} />
      {tabState === ModuleState.Success && <ResultsSection selectedReport={selectedOption}
        statements={statements && statements[selectedOption]}
        statementsType={statementsType}
        onDownload={onDownload} />
      }
      {showDisclosure && <UsDisclosureInfo />}
    </Grid.Col>
  )
}
