import React, { FC, useEffect, useState } from "react";
import { StyleProvider } from "@cet/components";
import { ClientType } from "@cet/interfaces";
import { Account, BasicInfoService, ClientAccountDetailsService } from "@cet/services";
import { Statements } from "./Statements";
import ClientAccountService from "./services/ClientAccountService";
import BootstrapService from "./services/BootstrapService";
import { ENV } from "./types";

const DOMAIN = {
  LIVE: "https://www.ig.com",
  DEMO: "https://demo.ig.com",
  UAT: "https://web.ig.com",
  TEST: "https://net.ig.com",
};

export interface AppProps {
  accountId: string;
  env: ENV;
  cst: string;
  clientType: ClientType;
}

const enrichWithProxyAccounts = async (
  account: Account,
  clientType: ClientType,
): Promise<void> => {
  const basicInfoService = BasicInfoService.getInstance<BasicInfoService>();
  const clientAccountDetailsService =
    ClientAccountDetailsService.getInstance<ClientAccountDetailsService>();
  const clientContext = basicInfoService.getClientContext(clientType);

  account.proxyAccounts = await clientAccountDetailsService.getProxyAccounts(
    account,
    clientContext,
  );
};

export const Root: FC<AppProps> = ({ accountId, clientType, env, cst }) => {
  const [account, setAccount] = useState<Account>();
  useEffect(() => {
    (async () => {
      await BootstrapService.runBootstrap(
        accountId,
        env,
        cst,
        DOMAIN[env],
        clientType,
      );
      const accountToEnrich = ClientAccountService.getAccount(accountId, clientType);
      await enrichWithProxyAccounts(accountToEnrich, clientType);
      setAccount(accountToEnrich);
    })();
  }, [accountId, clientType, cst, env]);
  return <StyleProvider>
    {account && <Statements account={account} locale={account.locale} showTitle />}
  </StyleProvider>;
};

export default Root;
