import { ErrorType, ModuleState } from "@cet/interfaces";
import { FetchStatementsDispatchParams, TabState } from "./types";
import {
  IStatementDto,
  StatementsFactory,
  StatementsService,
} from "../services";
import { StatementsType } from "../types";

const calculateTabState = (
  statementsForPeriod: IStatementDto[],
  type: StatementsType,
): TabState => {
  if (statementsForPeriod.length > 0) {
    return ModuleState.Success;
  }

  if (type === StatementsType.LEVERAGED || type === StatementsType.PHYSICAL) {
    return ErrorType.NoDataForPeriod;
  }
  return ErrorType.NoData;
};

let endDate;
let startDate;
let currentSelectedReport;

// Only for TESTS
export const setDate = (newStartDate: string, newEndDate: string): void => {
  endDate = newEndDate;
  startDate = newStartDate;
};

export const fetchStatementsDispatch = ({
  account,
  dispatcher,
  selectedReport,
  statements,
  statementsType,
  actionType,
  tabState,
  fromDate,
  toDate,
}: FetchStatementsDispatchParams): Promise<void> => {
  if (tabState === ModuleState.Loading) {
    return;
  }

  dispatcher({
    payload: {
      tabState: ModuleState.Loading,
    },
    type: actionType,
  });

  const isTastyYearlyReport: boolean = StatementsType.TASTYYEARLY === statementsType;
  const isTastyYearlyReportChanged: boolean = isTastyYearlyReport && (currentSelectedReport !== undefined ? selectedReport !== currentSelectedReport : false);

  if (statements && startDate === fromDate && endDate === toDate && !(isTastyYearlyReport && isTastyYearlyReportChanged)) {
    dispatcher({
      payload: {
        selectedReport,
        tabState: calculateTabState(statements[selectedReport], statementsType),
      },
      type: actionType,
    });
    return;
  }

  currentSelectedReport = isTastyYearlyReport ? selectedReport : currentSelectedReport;
  startDate = fromDate;
  endDate = toDate;
  const statementResponse =
    statementsType === StatementsType.TASTYYEARLY
      ? StatementsService.getStatements(
          account,
          statementsType,
          fromDate,
          toDate,
          selectedReport,
        )
      : StatementsService.getStatements(
          account,
          statementsType,
          fromDate,
          toDate,
        );
  statementResponse
    .then((response: IStatementDto[]) => {
      const statementsResponse = response || [];
      const newStatements = StatementsFactory.buildStatementsModel(
        statementsResponse,
        statementsType,
        selectedReport,
      );
      dispatcher({
        payload: {
          selectedReport,
          statements: newStatements,
          tabState: calculateTabState(
            newStatements[selectedReport],
            statementsType,
          ),
        },
        type: actionType,
      });
    })
    .catch(() => {
      dispatcher({
        payload: {
          tabState: ErrorType.ServerError,
        },
        type: actionType,
      });
    });
};
