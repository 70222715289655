import React, { Dispatch, FC } from "react";
import { MisTrackingEventsService } from "@cet/services";
import { ErrorType } from "@cet/interfaces";
import { dateToString } from "@cet/utils";
import {
  fetchStatementsDispatch,
  FetchStatementsDispatchParams,
  SelectedPeriod,
  TabAction,
  TabStore,
} from "../store";
import { IStatementsTab, StatementsType, TabContentProps } from "../types";
import { IStatementDto, StatementsService } from "../services";
import { useStatements } from "../hooks";

type StatementsContentWrapperProps = {
  statementsTab: IStatementsTab;
  statementsState: TabStore;
  statementsType: StatementsType;
  dispatcher: Dispatch<TabAction>;
};

export const StatementsContentWrapper: FC<StatementsContentWrapperProps> = ({
  statementsTab,
  dispatcher,
  statementsState,
}) => {
  const StatementContent: FC<TabContentProps> = statementsTab.tabContent;
  const { account } = useStatements();
  const misTrackingEventsService =
    MisTrackingEventsService.getInstance<MisTrackingEventsService>();

  const onDownload = (
    statement: IStatementDto,
    statementsType: StatementsType,
  ): Promise<void> => {
    const trackId = `download${statementsType}Statement`;

    misTrackingEventsService.trackClick(trackId);

    try {
      return StatementsService.downloadStatement(
        statementsType,
        account,
        statement,
      );
    } catch (error) {
      dispatcher({
        payload: {
          tabState: ErrorType.ServerError,
        },
        type: statementsTab.actionType,
      });
    }
    return null;
  };

  const onSubmit = (
    reportPeriod: SelectedPeriod,
    statementsType: StatementsType,
    startDate: Date,
    endDate: Date,
  ): void => {
    const params: FetchStatementsDispatchParams = {
      account,
      actionType: statementsTab.actionType,
      dispatcher,
      fromDate: dateToString(startDate, "dd/MM/yyyy"),
      selectedReport: reportPeriod,
      statements: statementsState.statements,
      statementsType,
      tabState: statementsState.tabState,
      toDate: dateToString(endDate, "dd/MM/yyyy"),
    };
    const trackId = `show${statementsType}Statement`;

    misTrackingEventsService.trackClick(trackId);

    fetchStatementsDispatch(params);
  };

  return (
    <StatementContent
      statements={statementsState.statements}
      dispatcher={dispatcher}
      options={statementsTab.options}
      selectedOption={statementsState.selectedReport}
      showDisclosure={statementsTab.usDisclosure}
      statementsType={statementsTab.statementsType}
      onSubmit={(period, startDate, endDate): void =>
        onSubmit(period, statementsTab.statementsType, startDate, endDate)
      }
      onDownload={(statement: IStatementDto): Promise<void> =>
        onDownload(statement, statementsTab.statementsType)
      }
      tabState={statementsState.tabState}
    />
  );
};
